
  import {
    defineComponent,
    ref,
    onMounted,
    computed,
    onBeforeUnmount,
  } from 'vue';
  import { useRouter } from 'vue-router';
  import Datatable from '@/components/kt-datatable/KTDatatable.vue';
  import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
  import { useI18n } from 'vue-i18n/index';
  import { useStore } from 'vuex';
  import { Actions } from '@/store/enums/StoreEnums';
  import { useAbility } from '@casl/vue';
  import Swal from 'sweetalert2/dist/sweetalert2.js';

  export default defineComponent({
    name: 'drivers-listing',
    components: {
      Datatable,
    },
    async setup() {
      const { t, te } = useI18n();
      const store = useStore();
      const router = useRouter();
      const { can } = useAbility();

      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      onMounted(async () => {
        setCurrentPageBreadcrumbs(translate('driversListing'), [
          translate('assets'),
        ]);
        loading.value = true;
        await store.dispatch(Actions.GET_DRIVERS);
        loading.value = false;
      });

      const searchItems = async (input) => {
        await store.dispatch(Actions.UPDATE_DRIVERS_FILTER, {
          driverName: input,
        });
        await store.dispatch(Actions.GET_DRIVERS);
      };

      onBeforeUnmount(() => {
        store.dispatch(Actions.RESET_DRIVERS_STORE);
      });

      const loading = ref(false);
      const tableHeader = ref([
        {
          name: translate('fullName'),
          key: 'fullName',
          sortable: true,
        },
        {
          name: translate('email'),
          key: 'email',
          sortable: true,
        },
        {
          name: translate('mobile'),
          key: 'mobile',
          sortable: true,
        },
        {
          name: translate('status'),
          key: 'isActive',
          sortable: false,
        },
        {
          name: translate('actions'),
          key: 'actions',
        },
      ]);
      const deleteDriver = async (driver) => {
        const result = await Swal.fire({
          title: translate('areYouSureQ'),
          icon: 'warning',
          showCancelButton: true,
          buttonsStyling: false,
          confirmButtonText: translate('confirm'),
          cancelButtonText: translate('cancel'),
          customClass: {
            confirmButton: 'btn fw-bold btn-warning',
            cancelButton: 'btn btn-light',
          },
        });

        if (result.value) {
          loading.value = true;
          await store.dispatch(Actions.DELETE_DRIVER, driver.id);
          await store.dispatch(Actions.GET_DRIVERS);
          loading.value = false;
          const [errorName] = Object.keys(store.getters.getErrors);
          const error = store.getters.getErrors[errorName];
          if (error) {
            Swal.fire({
              text: translate(error[0]),
              icon: 'error',
              buttonsStyling: false,
              confirmButtonText: translate('tryAgainExcl'),
              customClass: {
                confirmButton: 'btn fw-bold btn-light-danger',
              },
            });
          }
        }
      };

      const editDriver = (id) => {
        router.push({ name: 'driver-editing', params: { id } });
      };

      const driverPayment = (id) => {
        router.push({ name: 'driver-payment', params: { id } });
      };

      const shipmentHistory = (id) => {
        router.push({ name: 'shipment-history', params: { id } });
      };

      const goTo = (pageName) => {
        router.push({ name: pageName });
      };

      const pageChanged = async (val) => {
        loading.value = true;
        await store.dispatch(Actions.UPDATE_DRIVER_CURRENT_PAGE, val);
        await store.dispatch(Actions.GET_DRIVERS);
        loading.value = false;
      };

      return {
        tableData: computed(() => store.getters.driversList),
        tableHeader,
        driverPayment,
        shipmentHistory,
        input: '',
        searchItems,
        deleteDriver,
        translate,
        totalItems: computed(() => store.getters.driversListCount),
        pageChanged,
        goTo,
        editDriver,
        loading,
        can,
      };
    },
  });
